// <label> corresponds to full names displayed for users
// <value> corresponds to organization.shortname stored in codePost database

const universities = [
  { label: 'Abilene Christian University', value: 'acu' },
  { label: 'Abraham Lincoln University ', value: 'abraham lincoln' },
  { label: 'Adams State University ', value: 'adams state' },
  { label: 'Adelphi University', value: 'adelphi' },
  { label: 'Agnes Scott College', value: 'scottlan' },
  { label: 'Aiken Technical College ', value: 'aiken technical' },
  { label: 'Air Force Institute of Technology', value: 'afit' },
  { label: 'Alabama A & M University ', value: 'alabama a & m' },
  { label: 'Alabama A&M University', value: 'aamu' },
  { label: 'Alabama State University', value: 'alasu' },
  { label: 'Alamance Community College ', value: 'alamance' },
  { label: 'Alaska Pacific University', value: 'alaska pacific u' },
  { label: 'Albany State University ', value: 'albany state' },
  { label: 'Albany Technical College ', value: 'albany technical' },
  { label: 'Albertson College of Idaho', value: 'acofi' },
  { label: 'Albertus Magnus College ', value: 'albertus magnus' },
  { label: 'Albion College', value: 'albion' },
  { label: 'Albright College ', value: 'albright' },
  { label: 'Alcorn State University ', value: 'alcorn state' },
  { label: 'Alderson Broaddus College', value: 'alderson broaddu' },
  { label: 'Alfred State College ', value: 'alfred state' },
  { label: 'Alfred University', value: 'alfred' },
  { label: 'Allan Hancock College ', value: 'allan hancock' },
  { label: 'Allegany College of Maryland ', value: 'allegany' },
  { label: 'Allegheny College', value: 'alleg' },
  { label: 'Allen County Community College ', value: 'allen county' },
  { label: 'Allentown College of Saint Francis de Sales', value: 'allencol' },
  { label: 'Alma College', value: 'alma' },
  { label: 'Alpena Community College ', value: 'alpena' },
  { label: 'Alvernia University ', value: 'alvernia' },
  { label: 'Alverno College', value: 'alverno' },
  { label: 'Alvin Community College ', value: 'alvin' },
  { label: 'Amarillo College ', value: 'amarillo' },
  { label: 'Ambassador University', value: 'ambassador' },
  { label: 'American Baptist College ', value: 'american baptist' },
  { label: 'American Business and Technology University ', value: 'american business and technology' },
  { label: 'American Coastline University', value: 'amercoastuniv' },
  { label: 'American Graduate School of International Management', value: 't bird' },
  { label: 'American International College', value: 'aic' },
  { label: 'American River College ', value: 'american river' },
  { label: 'American Studies Program Willamette University ', value: 'american studies program willamette' },
  { label: 'American University', value: 'american' },
  { label: 'Amherst College', value: 'amherst' },
  { label: 'Ancilla College ', value: 'ancilla' },
  { label: 'Anderson University ', value: 'anderson' },
  { label: 'Andrews University', value: 'andrews' },
  { label: 'Angeles College ', value: 'angeles' },
  { label: 'Angelina College ', value: 'angelina' },
  { label: 'Angelo State University', value: 'angelo' },
  { label: 'Anna Maria College ', value: 'anna maria' },
  { label: 'Anne Arundel Community College ', value: 'anne arundel' },
  { label: 'Anoka Technical College ', value: 'anoka technical' },
  { label: 'Anoka Ramsey Community College ', value: 'anoka ramsey' },
  { label: 'Antioch College', value: 'college' },
  { label: 'Antioch New England', value: 'antiochne' },
  { label: 'Antioch University ', value: 'antioch' },
  { label: 'Antioch University Los Angeles', value: 'antiochla' },
  { label: 'Antioch University Seattle', value: 'seattleantioch' },
  { label: 'Appalachian State University', value: 'appstate' },
  { label: 'Aquinas College', value: 'aquinas' },
  { label: 'Arapahoe Community College ', value: 'arapahoe' },
  { label: 'Arcadia University ', value: 'arcadia' },
  { label: 'Arizona State University East', value: 'arizona state un' },
  { label: 'Arizona State University West', value: 'west' },
  { label: 'Arizona State University', value: 'asu' },
  { label: 'Arizona Western College', value: 'awc' },
  { label: 'Arkansas Northeastern College ', value: 'arkansas northeastern' },
  { label: 'Arkansas State University   Beebe ', value: 'arkansas state   beebe' },
  { label: 'Arkansas State University   Newport ', value: 'arkansas state   newport' },
  { label: 'Arkansas State University ', value: 'arkansas state' },
  { label: 'Arkansas State University Mid South ', value: 'arkansas state mid south' },
  { label: 'Arkansas State University, Jonesboro', value: 'astate' },
  { label: 'Arkansas Tech University', value: 'atu' },
  { label: 'Armstrong State College', value: 'armstrong' },
  { label: 'Ashland Community and Technical College ', value: 'ashland community and technical' },
  { label: 'Ashland University', value: 'ashland' },
  { label: 'Asnuntuck Community College ', value: 'asnuntuck' },
  { label: 'Assumption College', value: 'assumption' },
  { label: 'Athens State College', value: 'athens state col' },
  { label: 'Athens State University ', value: 'athens state' },
  { label: 'Athens Technical College ', value: 'athens technical' },
  { label: 'Auburn University   Montgomery', value: 'aum' },
  { label: 'Auburn University Montgomery ', value: 'auburn montgomery' },
  { label: 'Auburn University', value: 'auburn' },
  { label: 'Augsburg College', value: 'augsburg' },
  { label: 'Augsburg University ', value: 'augsburg' },
  { label: 'Augusta State University ', value: 'augusta state' },
  { label: 'Augusta Technical College ', value: 'augusta technical' },
  { label: 'Augustana College', value: 'augustana' },
  { label: 'Augustana University ', value: 'augustana' },
  { label: 'Aurora University', value: 'aurora' },
  { label: 'Austin College', value: 'austinc' },
  { label: 'Austin Community College ', value: 'austin' },
  { label: 'Austin Peay State University', value: 'apsu' },
  { label: 'Averett College', value: 'averett' },
  { label: 'Averett University ', value: 'averett' },
  { label: 'Avila College', value: 'avila' },
  { label: 'Avila University ', value: 'avila' },
  { label: 'Azusa Pacific Online University ', value: 'azusa pacific online' },
  { label: 'Azusa Pacific University', value: 'apu' },
  { label: 'Babson College', value: 'babson' },
  { label: 'Baker University', value: 'bakeru' },
  { label: 'Bakersfield College ', value: 'bakersfield' },
  { label: 'Baldwin Wallace University ', value: 'baldwin wallace' },
  { label: 'Baldwin Wallace College', value: 'baldwinw' },
  { label: 'Ball State University', value: 'bsu' },
  { label: 'Baltimore International College ', value: 'baltimore international' },
  { label: 'Baptist Bible College', value: 'bbc' },
  { label: 'Bard College', value: 'bard' },
  { label: 'Barnard College ', value: 'barnard' },
  { label: 'Barry University', value: 'barry' },
  { label: 'Bastyr University', value: 'bastyr' },
  { label: 'Bates College', value: 'bates' },
  { label: 'Baton Rouge Community College ', value: 'baton rouge' },
  { label: 'Bay Path University ', value: 'bay path' },
  { label: 'Baylor College of Medicine', value: 'bcm' },
  { label: 'Baylor University', value: 'baylor' },
  { label: 'Beaufort County Community College ', value: 'beaufort county' },
  { label: 'Beaver College', value: 'beaver' },
  { label: 'Becker College ', value: 'becker' },
  { label: 'Belhaven University ', value: 'belhaven' },
  { label: 'Bellarmine University ', value: 'bellarmine' },
  { label: 'Bellevue College ', value: 'bellevue' },
  { label: 'Bellevue University ', value: 'bellevue' },
  { label: 'Bellingham Technical College ', value: 'bellingham technical' },
  { label: 'Belmont College ', value: 'belmont' },
  { label: 'Belmont University', value: 'belmont' },
  { label: 'Beloit College', value: 'beloit' },
  { label: 'Bemidji State University', value: 'bemidji' },
  { label: 'Benedictine College', value: 'benedictine' },
  { label: 'Benedictine University ', value: 'benedictine' },
  { label: 'Benjamin Franklin Institute of Technology ', value: 'benjamin franklin institute of technology' },
  { label: 'Bennington College', value: 'bennington' },
  { label: 'Bentley College', value: 'bentley' },
  { label: 'Bentley University ', value: 'bentley' },
  { label: 'Berea College', value: 'berea' },
  { label: 'Bergen Community College ', value: 'bergen' },
  { label: 'Berkeley City College ', value: 'berkeley city' },
  { label: 'Berklee College of Music', value: 'berklee' },
  { label: 'Berkshire Community College ', value: 'berkshire' },
  { label: 'Berry College ', value: 'berry' },
  { label: 'Bethany College', value: 'bethany' },
  { label: 'Bethel College and Seminary', value: 'bethel' },
  { label: 'Bethel College', value: 'bethelks' },
  { label: 'Bethel University ', value: 'bethel' },
  { label: 'Bethesda University ', value: 'bethesda' },
  { label: 'Big Bend Community College ', value: 'big bend' },
  { label: 'Big Sandy Community and Technical College ', value: 'big sandy community and technical' },
  { label: 'Biola University', value: 'biola' },
  { label: 'Birmingham Southern College', value: 'bsc' },
  { label: 'Bismarck State College ', value: 'bismarck state' },
  { label: 'Black Hills State University', value: 'bhsu' },
  { label: 'Blackburn College ', value: 'blackburn' },
  { label: 'Bladen Community College ', value: 'bladen' },
  { label: 'Blinn College District ', value: 'blinn district' },
  { label: 'Bloomfield College ', value: 'bloomfield' },
  { label: 'Bloomsburg University of Pennsylvania', value: 'bloomu' },
  { label: 'Blue Mountain College ', value: 'blue mountain' },
  { label: 'Blue Mountain Community College ', value: 'blue mountain' },
  { label: 'Blue River Community College ', value: 'blue river' },
  { label: 'Bluefield College ', value: 'bluefield' },
  { label: 'Bluefield State College ', value: 'bluefield state' },
  { label: 'Bluegrass Community and Technical College ', value: 'bluegrass' },
  { label: 'Bluffton College', value: 'bluffton' },
  { label: 'Bluffton University ', value: 'bluffton' },
  { label: 'Bob Jones University', value: 'bju' },
  { label: 'Boise State University', value: 'idbsu' },
  { label: 'Boston College', value: 'bc' },
  { label: 'Boston Graduate School of Psychoanalysis', value: 'bgsp' },
  { label: 'Boston University', value: 'bu' },
  { label: 'Bowdoin College', value: 'bowdoin' },
  { label: 'Bowie State University', value: 'bsu' },
  { label: 'Bowling Green State University', value: 'bgsu' },
  { label: 'Bradley University', value: 'bradley' },
  { label: 'Brandeis University', value: 'brandeis' },
  { label: 'Brenau University', value: 'brenau' },
  { label: 'Briar Cliff College', value: 'briar cliff' },
  { label: 'Briar Cliff University ', value: 'briar cliff' },
  { label: 'BridgeValley Community and Technical College ', value: 'bridgevalley' },
  { label: 'Bridgewater College', value: 'bridgewater' },
  { label: 'Bridgewater State University ', value: 'bridgewater state' },
  { label: 'Brigham Young University   Hawaii ', value: 'brigham young   hawaii' },
  { label: 'Brigham Young University   Idaho ', value: 'brigham young   idaho' },
  { label: 'Brigham Young University Hawaii', value: 'byuh' },
  { label: 'Brigham Young University', value: 'byu' },
  { label: 'Brookhaven College ', value: 'brookhaven' },
  { label: 'Brooklyn Institute of Business Technology ', value: 'brooklyn institute of business technology' },
  { label: 'Brown University', value: 'brown' },
  { label: 'Bryant College', value: 'bryant' },
  { label: 'Bryant University ', value: 'bryant' },
  { label: 'Bryn Athyn College of the New Church ', value: 'bryn athyn' },
  { label: 'Bryn Mawr College', value: 'brynmawr' },
  { label: 'Bucknell University', value: 'bucknell' },
  { label: 'Buena Vista University', value: 'bvu' },
  { label: 'Bunker Hill Community College ', value: 'bunker hill' },
  { label: 'Butler County Community College ', value: 'butler county' },
  { label: 'Butler University', value: 'butler' },
  { label: 'Butte College ', value: 'butte' },
  { label: 'Cabell County Career Technology Center ', value: 'ctc' },
  { label: 'Cabrillo College ', value: 'cabrillo college' },
  { label: 'Cabrini University ', value: 'cabrini university' },
  { label: 'Caldwell Community College and Technical Institute ', value: 'caldwell' },
  { label: 'Calhoun Community College ', value: 'calhoun' },
  { label: 'California Baptist University ', value: 'cbu' },
  { label: 'California Coast University', value: 'calcoastuniv' },
  { label: 'California Institute of Technology', value: 'caltech' },
  { label: 'California Lutheran University', value: 'callutheran' },
  { label: 'California Maritime Academy', value: 'csum' },
  { label: 'California National University', value: 'cnuas' },
  { label: 'California Pacific University', value: 'california pacif' },
  { label: 'California Polytechnic State University, San Luis Obispo', value: 'calpoly' },
  { label: 'California School of Professional Psychology', value: 'california schoo' },
  { label: 'California State Polytechnic University, Pomona', value: 'csupomona' },
  { label: 'California State University System', value: 'calstate' },
  { label: 'California State University, Bakersfield', value: 'csubak' },
  { label: 'California State University, Chico', value: 'csuchico' },
  { label: 'California State University, Dominguez Hills', value: 'csudh' },
  { label: 'California State University, Fresno', value: 'csufresno' },
  { label: 'California State University, Fullerton', value: 'fullerton' },
  { label: 'California State University, Hayward', value: 'csuhayward' },
  { label: 'California State University, Long Beach', value: 'csulb' },
  { label: 'California State University, Los Angeles', value: 'calstatela' },
  { label: 'California State University, Monterey Bay', value: 'monterey' },
  { label: 'California State University, Northridge', value: 'csun' },
  { label: 'California State University, Sacramento', value: 'csus' },
  { label: 'California State University, San Bernardino', value: 'csusb' },
  { label: 'California State University, San Jose', value: 'sjsu' },
  { label: 'California State University, San Marcos', value: 'csusm' },
  { label: 'California State University, Stanislaus', value: 'csustan' },
  { label: 'California University of Pennsylvania', value: 'cup' },
  { label: 'Calvin College', value: 'calvin' },
  { label: 'Calvin University ', value: 'calvin university' },
  { label: 'Camden County College ', value: 'camden county college' },
  { label: 'Cameron College ', value: 'cameron university' },
  { label: 'Cameron University', value: 'cameron' },
  { label: 'Campbell University', value: 'campbell' },
  { label: 'Campbellsville College', value: 'campbellsvil' },
  { label: 'Campbellsville University ', value: 'campbellsville' },
  { label: 'Canadian Valley Technology Center ', value: 'cv tech' },
  { label: 'Canisius College', value: 'canisius' },
  { label: 'Cape Fear Community College ', value: 'cfcc' },
  { label: 'Capital Community College ', value: 'capital community college' },
  { label: 'Capital University ', value: 'capital university' },
  { label: 'Capitol Technology University ', value: 'capitol technology university' },
  { label: 'Cardinal Stritch University ', value: 'cardinal stritch university' },
  { label: 'Career and Technology Education Centers of Licking County ', value: 'ctec' },
  { label: 'Career Technology Center of Lackawanna County ', value: 'ctc' },
  { label: 'Caribbean University ', value: 'cis' },
  { label: 'Carl Albert State College ', value: 'carl albert state college' },
  { label: 'Carleton College', value: 'carleton' },
  { label: 'Carlow College', value: 'carlow' },
  { label: 'Carlow University ', value: 'carlow' },
  { label: 'Carnegie Mellon University', value: 'cmu' },
  { label: 'Carroll College', value: 'carroll' },
  { label: 'Carroll University ', value: 'carroll university' },
  { label: 'Carson Newman College', value: 'cn' },
  { label: 'Carthage College', value: 'carthage' },
  { label: 'Case Western Reserve University', value: 'cwru' },
  { label: 'Casper College ', value: 'casper' },
  { label: 'Castleton State University', value: 'csc' },
  { label: 'Castleton University ', value: 'castleton' },
  { label: 'Catawba College ', value: 'catawba' },
  { label: 'Catawba Valley Community College ', value: 'catawba valley' },
  { label: 'Cayuga County Community College ', value: 'cayuga community college' },
  { label: 'Cedar Crest College ', value: 'cedar crest' },
  { label: 'Cedarville College', value: 'cedarville' },
  { label: 'Centenary College of Louisiana', value: 'centenary' },
  { label: 'Centenary University ', value: 'centenary' },
  { label: 'Centra College ', value: 'centra college' },
  { label: 'Central Alabama Community College ', value: 'cacc' },
  { label: 'Central Arizona College ', value: 'central arizona' },
  { label: 'Central Baptist College ', value: 'cbc' },
  { label: 'Central Carolina Technical College ', value: 'central carolina tech' },
  { label: 'Central College', value: 'central' },
  { label: 'Central Connecticut State University', value: 'ccsu' },
  { label: 'Central Lakes College ', value: 'clc' },
  { label: 'Central Methodist College', value: 'cmc' },
  { label: 'Central Methodist University ', value: 'central methodist' },
  { label: 'Central Michigan University', value: 'cmich' },
  { label: 'Central Missouri State University', value: 'cmsu' },
  { label: 'Central Ohio Technical College ', value: 'cotc' },
  { label: 'Central Oregon Community College ', value: 'central oregon community college' },
  { label: 'Central Piedmont Community College ', value: 'central piedmont' },
  { label: 'Central State University ', value: 'central state university' },
  { label: 'Central Texas College ', value: 'central texas college' },
  { label: 'Central Washington University', value: 'cwu' },
  { label: 'Centre College', value: 'centre' },
  { label: 'Century College ', value: 'century college' },
  { label: 'Cerritos College ', value: 'cerritos college' },
  { label: 'Cerro Coso Community College ', value: 'cerro coso' },
  { label: 'Chadron State College', value: 'csc' },
  { label: 'Chaffey College ', value: 'chaffey college' },
  { label: 'Champlain College', value: 'champlain' },
  { label: 'Chapman University', value: 'chapman' },
  { label: 'Charleston Southern University ', value: 'charleston southern university' },
  { label: 'Chatfield College ', value: 'chatfield college' },
  { label: 'Chatham College', value: 'chatham' },
  { label: 'Chatham University ', value: 'chatham' },
  { label: 'Chattahoochee Valley Community College ', value: 'chattahoochee valley' },
  { label: 'Chesapeake College', value: 'chesapeake' },
  { label: 'Chestnut Hill College ', value: 'chestnut hill' },
  { label: 'Cheyney University', value: 'cheyney' },
  { label: 'Chowan University ', value: 'chowan' },
  { label: 'Christian Brothers University', value: 'cbu' },
  { label: 'Christian Theological Seminary', value: 'cts' },
  { label: 'Christopher Newport University', value: 'cnu' },
  { label: 'Cisco Junior College ', value: 'cisco' },
  { label: 'Citrus College ', value: 'citrus' },
  { label: 'City College Inc. ', value: 'city college' },
  { label: 'City University of New York', value: 'cuny' },
  { label: 'City University', value: 'cityu' },
  { label: 'Claflin University ', value: 'claflin' },
  { label: 'Claremont Graduate School', value: 'cgs' },
  { label: 'Claremont McKenna College', value: 'mckenna' },
  { label: 'Clarion University of Pennsylvania', value: 'clarion' },
  { label: 'Clark University', value: 'clarku' },
  { label: 'Clarke College', value: 'clarke' },
  { label: 'Clarke University ', value: 'clarke' },
  { label: 'Clarks Summit University ', value: 'clarks summit' },
  { label: 'Clarkson College ', value: 'clarkson' },
  { label: 'Clarkson University', value: 'clarkson' },
  { label: 'Clayton State College', value: 'csc' },
  { label: 'Clemson University', value: 'clemson' },
  { label: 'Cleveland Community College ', value: 'cleveland' },
  { label: 'Cleveland State University', value: 'csuohio' },
  { label: 'Clinch Valley College', value: 'clinch' },
  { label: 'Cloud County Community College ', value: 'cloud county' },
  { label: 'Clover Park Technical College ', value: 'clover park technical' },
  { label: 'Coahoma Community College ', value: 'coahoma' },
  { label: 'Coastal Carolina Community College ', value: 'coastal carolina' },
  { label: 'Coastal Carolina University', value: 'coastal' },
  { label: 'Coastline Community College ', value: 'coastline' },
  { label: 'Cochise College ', value: 'cochise' },
  { label: 'Coe College', value: 'coe' },
  { label: 'Cogswell Polytechnical College ', value: 'cogswell polytechnical' },
  { label: 'Coker College', value: 'coker' },
  { label: 'Coker University ', value: 'coker' },
  { label: 'Colby College', value: 'colby' },
  { label: 'Colgate University', value: 'colgate' },
  { label: 'College of Alameda ', value: 'college of alameda' },
  { label: 'College of Charleston', value: 'cofc' },
  { label: 'College of Coastal Georgia ', value: 'college of coastal georgia' },
  { label: 'College of DuPage ', value: 'college of dupage' },
  { label: 'College of Eastern Utah', value: 'ceu' },
  { label: 'College of Idaho ', value: 'college of idaho' },
  { label: 'College of Lake County ', value: 'college of lake county' },
  { label: 'College of Marin ', value: 'college of marin' },
  { label: 'College of Our Lady of the Elms ', value: 'college of our lady of the elms' },
  { label: 'College of Saint Benedict   Saint John?s University ', value: 'college of saint benedict   saint john?s' },
  { label: 'College of Saint Benedict', value: 'csbsju' },
  { label: 'College of Saint Catherine', value: 'stkate' },
  { label: 'College of Saint Elizabeth ', value: 'college of saint elizabeth' },
  { label: 'College of Saint Mary ', value: 'saint marys' },
  { label: 'College of Saint Rose', value: 'strose' },
  { label: 'College of Saint Scholastica ', value: 'college of saint scholastica' },
  { label: 'College of San Mateo ', value: 'college of san mateo' },
  { label: 'College of Southern Idaho ', value: 'college of southern idaho' },
  { label: 'College of St Joseph ', value: 'st. josephs' },
  { label: 'College of St. Francis', value: 'stfrancis' },
  { label: 'College of St. Scholastica', value: 'css' },
  { label: 'College of the Albemarle ', value: 'college of the albemarle' },
  { label: 'College of the Atlantic', value: 'coa' },
  { label: 'College of the Canyons ', value: 'college of the canyons' },
  { label: 'College of the Holy Cross', value: 'holycross' },
  { label: 'College of the Mainland ', value: 'college of the mainland' },
  { label: 'College of the Marshall Islands ', value: 'college of the marshall islands' },
  { label: 'College of the Ouachitas ', value: 'college of the ouachitas' },
  { label: 'College of the Ozarks ', value: 'college of the ozarks' },
  { label: 'College of the Sequoias ', value: 'college of the sequoias' },
  { label: 'College of the Siskiyous ', value: 'college of the siskiyous' },
  { label: 'College of Western Idaho ', value: 'college of western idaho' },
  { label: 'College of William and Mary', value: 'wm' },
  { label: 'Collin County Community College District ', value: 'collin county ' },
  { label: 'Colorado Christian University', value: 'ccu' },
  { label: 'Colorado College', value: 'cc' },
  { label: 'Colorado Mesa University ', value: 'colorado mesa' },
  { label: 'Colorado School of Mines', value: 'mines' },
  { label: 'Colorado State University', value: 'colostate' },
  { label: 'Columbia College Chicago', value: 'colum' },
  { label: 'Columbia Gorge Community College ', value: 'columbia gorge' },
  { label: 'Columbia Southern University', value: 'colsouth' },
  { label: 'Columbia State Community College ', value: 'columbia state' },
  { label: 'Columbia Union College', value: 'cuc' },
  { label: 'Columbia University', value: 'columbia' },
  { label: 'Columbus State University ', value: 'columbus state' },
  { label: 'Columbus Technical College ', value: 'columbus technical' },
  { label: 'Community College of Aurora ', value: 'community of aurora' },
  { label: 'Community College of Denver ', value: 'community of denver' },
  { label: 'Community College of Rhode Island ', value: 'community of rhode island' },
  { label: 'Community College of the Air Force ', value: 'community of the air force' },
  { label: 'Compton Community College ', value: 'compton' },
  { label: 'Concord University ', value: 'concord' },
  { label: 'Concordia College ', value: 'concordia' },
  { label: 'Concordia College Ann Arbor', value: 'ccaa' },
  { label: 'Concordia College Moorhead', value: 'cord' },
  { label: 'Concordia College Seward', value: 'ccsn' },
  { label: 'Concordia College St. Paul', value: 'csp' },
  { label: 'Concordia University ', value: 'concordia' },
  { label: 'Concordia University Irvine ', value: 'concordia irvine' },
  { label: 'Concordia University River Forest, Illinois', value: 'cuis' },
  { label: 'Concordia University Texas ', value: 'concordia texas' },
  { label: 'Connecticut College', value: 'conncoll' },
  { label: 'Connors State College ', value: 'connors state' },
  { label: 'Converse College ', value: 'converse' },
  { label: 'Copiah Lincoln Community College ', value: 'copiah lincoln' },
  { label: 'Coppin State College', value: 'coppin' },
  { label: 'Coppin State University ', value: 'coppin state' },
  { label: 'Corban University ', value: 'corban' },
  { label: 'Cornell College', value: 'cornell iowa' },
  { label: 'Cornell University', value: 'cornell' },
  { label: 'Cornerstone College', value: 'cornerstone coll' },
  { label: 'Cornerstone University ', value: 'cornerstone' },
  { label: 'Corning Community College ', value: 'corning' },
  { label: 'Cosumnes River College ', value: 'cosumnes river' },
  { label: 'Cottey College ', value: 'cottey' },
  { label: 'County College of Morris ', value: 'county of morris' },
  { label: 'Covenant College ', value: 'covenant' },
  { label: 'Cowley College ', value: 'cowley' },
  { label: 'Crafton Hills College ', value: 'crafton hills' },
  { label: 'Craven Community College ', value: 'craven' },
  { label: 'Creighton University', value: 'creighton' },
  { label: 'Cuesta College ', value: 'cuesta' },
  { label: 'Culver Stockton College ', value: 'culver stockton' },
  { label: 'Curry College', value: 'curry' },
  { label: 'Cuyahoga Community College ', value: 'cuyahoga' },
  { label: 'Cuyamaca College ', value: 'cuyamaca' },
  { label: 'Cypress College ', value: 'cypress' },
  { label: 'Dabney S Lancaster Community College ', value: 'dabney s lancaster' },
  { label: 'Daemen College', value: 'daemen' },
  { label: 'Dakota College at Bottineau ', value: 'dakota at bottineau' },
  { label: 'Dakota County Technical College ', value: 'dakota county technical' },
  { label: 'Dakota State University', value: 'dsu' },
  { label: 'Dakota Wesleyan University', value: 'dwu' },
  { label: 'Dallas Baptist University', value: 'dbu' },
  { label: 'Dalton State College ', value: 'dalton state' },
  { label: 'Dana College', value: 'dana' },
  { label: 'Daniel Webster College', value: 'dwc' },
  { label: 'Danville Area Community College ', value: 'danville area' },
  { label: 'Dartmouth College', value: 'dartmouth' },
  { label: 'Davenport College Detroit College of Business', value: 'davenport' },
  { label: 'Davidson College', value: 'davidson' },
  { label: 'Davidson County Community College ', value: 'davidson county' },
  { label: 'Davis & Elkins College', value: 'dne' },
  { label: 'Daytona State College ', value: 'daytona state' },
  { label: 'De Anza College ', value: 'de anza' },
  { label: 'Defiance College ', value: 'defiance' },
  { label: 'Del Mar College ', value: 'del mar' },
  { label: 'Delaware State University', value: 'dsc' },
  { label: 'Delta College of Arts & Technology   Lafayette ', value: 'delta lafayette' },
  { label: 'Delta School of Business and Technology ', value: 'delta' },
  { label: 'Delta State University', value: 'deltast' },
  { label: 'Denison University', value: 'denison' },
  { label: 'DePaul University', value: 'depaul' },
  { label: 'DePauw University', value: 'depauw' },
  { label: 'Des Moines Area Community College ', value: 'des moines area' },
  { label: 'DeVry Institute of Technology Dallas', value: 'devry' },
  { label: 'DeVry Institute of Technology Phoenix', value: 'devry phx' },
  { label: 'DeVry Institute of Technology', value: 'devrycols' },
  { label: 'Diablo Valley College ', value: 'diablo valley' },
  { label: 'Dickinson College', value: 'dickinson' },
  { label: 'Dickinson State University', value: 'dsu' },
  { label: 'Digital Media Arts College ', value: 'digital media arts' },
  { label: 'Dillard University', value: 'dillard' },
  { label: 'Dixie State university ', value: 'dixie state' },
  { label: 'Doane University ', value: 'doane' },
  { label: 'Dominican College', value: 'dominican' },
  { label: 'Dominican University ', value: 'dominican' },
  { label: 'Dordt College', value: 'Dordt' },
  { label: 'Dordt University ', value: 'dordt' },
  { label: 'Dowling College', value: 'dowling' },
  { label: 'Drake University', value: 'drake' },
  { label: 'Drew University', value: 'drew' },
  { label: 'Drexel University', value: 'drexel' },
  { label: 'Drury College', value: 'drury' },
  { label: 'Drury University ', value: 'drury' },
  { label: 'Duke University', value: 'duke' },
  { label: 'Dunwoody College of Technology ', value: 'dunwoody' },
  { label: 'Duquesne University', value: 'duq' },
  { label: 'Dutchess Community College ', value: 'dutchess' },
  { label: 'Earlham College', value: 'earlham' },
  { label: 'East Carolina University', value: 'ecu' },
  { label: 'East Central College ', value: 'east central' },
  { label: 'East Central University', value: 'ecok' },
  { label: 'East Stroudsburg State University of Pennsylvania', value: 'esu' },
  { label: 'East Tennessee State University', value: 'etsu tn' },
  { label: 'East Texas State University', value: 'etsu' },
  { label: 'Eastern Connecticut State University', value: 'ecsu' },
  { label: 'Eastern Illinois University', value: 'eiu' },
  { label: 'Eastern Kentucky University', value: 'eku' },
  { label: 'Eastern Mennonite University', value: 'emu' },
  { label: 'Eastern Michigan University', value: 'emich' },
  { label: 'Eastern Nazarene College', value: 'eastern nazarene' },
  { label: 'Eastern New Mexico University', value: 'enmu' },
  { label: 'Eastern Oregon State College', value: 'eosc' },
  { label: 'Eastern Washington University', value: 'ewu' },
  { label: 'Edgewood College', value: 'edgewood' },
  { label: 'Edinboro University of Pennsylvania', value: 'edinboro' },
  { label: 'Elizabeth City State University', value: 'ecsu' },
  { label: 'Elizabethtown College', value: 'etown' },
  { label: 'Elmhurst College', value: 'elmhurst' },
  { label: 'Elon College', value: 'elon' },
  { label: 'Embry Riddle Aeronautical University, Arizona', value: 'erau' },
  { label: 'Embry Riddle Aeronautical University, Florida', value: 'erau' },
  { label: 'Emerson College', value: 'emerson' },
  { label: 'Emmanuel College', value: 'emmanuel' },
  { label: 'Emmaus Bible College', value: 'emmaus bible col' },
  { label: 'Emory & Henry College', value: 'emory & henry co' },
  { label: 'Emory University', value: 'emory' },
  { label: 'Emporia State University', value: 'emporia' },
  { label: 'Evergreen State College', value: 'evergreen' },
  { label: 'Fairfield University', value: 'fairfield univer' },
  { label: 'Fairleigh Dickinson University', value: 'fdu' },
  { label: 'Fairmont State College', value: 'fairmont' },
  { label: 'Fayetteville State University', value: 'fsufay' },
  { label: 'Ferris State University', value: 'ferris' },
  { label: 'Fielding Institute', value: 'fielding' },
  { label: 'Fisk University', value: 'fisk' },
  { label: 'Fitchburg State College', value: 'fsc' },
  { label: 'Florida Agricultural and Mechanical University', value: 'famu' },
  { label: 'Florida Atlantic University', value: 'fau' },
  { label: 'Florida Gulf Coast University', value: 'fgcu' },
  { label: 'Florida Institute of Technology', value: 'fit' },
  { label: 'Florida International University', value: 'fiu' },
  { label: 'Florida State University', value: 'fsu' },
  { label: 'Fontbonne College', value: 'fontbonne' },
  { label: 'Fordham University', value: 'fordham' },
  { label: 'Fort Hays State University', value: 'fhsu' },
  { label: 'Fort Lewis College', value: 'fortlewis' },
  { label: 'Franciscan University', value: 'franciscan unive' },
  { label: 'Franklin and Marshall College', value: 'fandm' },
  { label: 'Franklin Pierce Law Center', value: 'fplc' },
  { label: 'Franklin University', value: 'franklin' },
  { label: 'Fresno Pacific University', value: 'fresno' },
  { label: 'Friends University', value: 'friends' },
  { label: 'Frostburg State University', value: 'fsu' },
  { label: 'Fuller Theological Seminary', value: 'fuller' },
  { label: 'Furman University', value: 'furman' },
  { label: 'Gallaudet University', value: 'gallaudet' },
  { label: 'Gannon University', value: 'gannon' },
  { label: 'Geneva College', value: 'geneva' },
  { label: 'George Fox College', value: 'gfc' },
  { label: 'George Mason University', value: 'gmu' },
  { label: 'George Washington University', value: 'gwu' },
  { label: 'Georgetown University', value: 'georgetown' },
  { label: 'Georgia College', value: 'gac' },
  { label: 'Georgia Institute of Technology', value: 'gatech' },
  { label: 'Georgia Southern University', value: 'gasou' },
  { label: 'Georgia Southwestern College', value: 'gsw' },
  { label: 'Georgia State University', value: 'gsu' },
  { label: 'Georgian Court College', value: 'georgian' },
  { label: 'Gettysburg College', value: 'gettysburg' },
  { label: 'GMI Engineering and Management Institute', value: 'gmi' },
  { label: 'Golden Gate University', value: 'ggu' },
  { label: 'Goldey Beacom College', value: 'gbc' },
  { label: 'Gonzaga University', value: 'gonzaga' },
  { label: 'Goshen College', value: 'goshen' },
  { label: 'Goucher College', value: 'goucher' },
  { label: 'Governors State University', value: 'bgu' },
  { label: 'Grace College', value: 'grace' },
  { label: 'Graceland College', value: 'graceland' },
  { label: 'Grand Valley State University', value: 'gvsu' },
  { label: 'Greenleaf University', value: 'greenleaf' },
  { label: 'Grinnell College', value: 'grin' },
  { label: 'Grove City College ', value: 'grove city' },
  { label: 'Guilford College', value: 'guilford' },
  { label: 'Gustavus Adolphus College', value: 'gac' },
  { label: 'Gutenberg College', value: 'gutenberg colleg' },
  { label: 'Hamilton College', value: 'hamilton' },
  { label: 'Hamline University', value: 'hamline' },
  { label: 'Hampden Sydney College', value: 'hsc' },
  { label: 'Hampshire College', value: 'hampshire' },
  { label: 'Hampton University', value: 'hamptonu' },
  { label: 'Hanover College', value: 'hanover' },
  { label: 'Harding University', value: 'harding' },
  { label: 'Hartwick College', value: 'hartwick' },
  { label: 'Harvard University', value: 'harvard' },
  { label: 'Harvey Mudd College', value: 'hmc' },
  { label: 'Haskell Indian Nations University', value: 'haskell' },
  { label: 'Hastings College', value: 'hastings' },
  { label: 'Haverford College in Pennsylvania', value: 'haverford' },
  { label: 'Hawaii Pacific University', value: 'hpu' },
  { label: 'Heidelberg College', value: 'heidelberg' },
  { label: 'Hendrix College', value: 'hendrix' },
  { label: 'Hesston College', value: 'hesston' },
  { label: 'High Point University', value: 'highpoint' },
  { label: 'Hillsdale College', value: 'hillsdale' },
  { label: 'Hiram College', value: 'hiram' },
  { label: 'Hobart and William Smith Colleges', value: 'hws' },
  { label: 'Hofstra University', value: 'hofstra' },
  { label: 'Hollins College', value: 'hollins' },
  { label: 'Holy Cross College', value: 'holycross' },
  { label: 'Hood College', value: 'hood' },
  { label: 'Hope College', value: 'hope' },
  { label: 'Howard University', value: 'howard' },
  { label: 'Humboldt State University', value: 'humboldt' },
  { label: 'Hunter College', value: 'hunter' },
  { label: 'Huntingdon College', value: 'huntingdon' },
  { label: 'Huntington College', value: 'huntcol' },
  { label: 'ICI University', value: 'ici' },
  { label: 'Idaho State University', value: 'isu' },
  { label: 'Illinois Benedictine College', value: 'ibc' },
  { label: 'Illinois Institute of Technology', value: 'iit' },
  { label: 'Illinois State University', value: 'ilstu' },
  { label: 'Incarnate Word College', value: 'iwctx' },
  { label: 'Indiana Institute of Technology', value: 'indtech' },
  { label: 'Indiana State University', value: 'indstate' },
  { label: 'Indiana University at Bloomington', value: 'indiana' },
  { label: 'Indiana University at South Bend', value: 'iusb' },
  { label: 'Indiana University of Pennsylvania', value: 'iup' },
  { label: 'Indiana University Southeast at New Albany', value: 'ius' },
  { label: 'Indiana University System', value: 'indiana' },
  { label: 'Indiana University/Purdue University at Columbus', value: 'columbus' },
  { label: 'Indiana University/Purdue University at Fort Wayne', value: 'ipfw' },
  { label: 'Indiana University/Purdue University at Indianapolis', value: 'iupui' },
  { label: 'Indiana Wesleyan University, Marion', value: 'indwes' },
  { label: 'Inter American University of Puerto Rico Metropolitan Campus', value: 'metro' },
  { label: 'Iona College', value: 'iona' },
  { label: 'Iowa State University', value: 'iastate' },
  { label: 'Ithaca College', value: 'ithaca' },
  { label: 'Jackson State University', value: 'jsums' },
  { label: 'Jacksonville State University', value: 'jsu' },
  { label: 'Jacksonville University', value: 'ju' },
  { label: 'James Madison University', value: 'jmu' },
  { label: 'Jamestown College', value: 'jc' },
  { label: 'John Brown University', value: 'jbu' },
  { label: 'John F. Kennedy University', value: 'jfku' },
  { label: 'Johns Hopkins University', value: 'jhu' },
  { label: 'Johnson & Wales University Charleston', value: 'johnson & wales ' },
  { label: 'Johnson & Wales University', value: 'jwu' },
  { label: 'Johnson Bible College', value: 'jbc' },
  { label: 'Johnson C. Smith University', value: 'jcsu' },
  { label: 'Jones College', value: 'jones' },
  { label: 'Judson College', value: 'judson' },
  { label: 'Juniata College', value: 'juniata' },
  { label: 'Kalamazoo College', value: 'kzoo' },
  { label: 'Kansas State University', value: 'ksu' },
  { label: 'Kansas Wesleyan University', value: 'kwu' },
  { label: 'Kean College', value: 'kean' },
  { label: 'Keene State College', value: 'keene' },
  { label: 'Kent State University', value: 'kent' },
  { label: 'Kenyon College', value: 'kenyon' },
  { label: "King's College", value: 'kings' },
  { label: 'Knox College', value: 'knox' },
  { label: 'Kutztown University of Pennsylvania', value: 'kutztown' },
  { label: 'La Sierra University', value: 'lasierra' },
  { label: 'Lafayette College', value: 'lafayette' },
  { label: 'LaGrange College', value: 'lgc' },
  { label: 'Lake Forest College', value: 'lfc' },
  { label: 'Lake Superior State University', value: 'lssu' },
  { label: 'Lamar University', value: 'lamar' },
  { label: 'Langston University', value: 'lunet' },
  { label: 'Las Positas College ', value: 'las positas college' },
  { label: 'LaSalle University', value: 'lasalle' },
  { label: 'Lawrence Technological University', value: 'ltu' },
  { label: 'Lawrence University', value: 'lawrence' },
  { label: 'Le Moyne College', value: 'lemoyne' },
  { label: 'Lebanon Valley College', value: 'lvc' },
  { label: 'Lehigh Univervsity', value: 'lehigh' },
  { label: 'Lenoir Rhyne College', value: 'lrc' },
  { label: 'LeTourneau University', value: 'letu' },
  { label: 'Lewis & Clark College', value: 'lclark' },
  { label: 'Lewis University', value: 'lewisu' },
  { label: 'Lewis Clark State College', value: 'lcsc' },
  { label: 'Liberty University', value: 'liberty' },
  { label: 'Lincoln University', value: 'lincoln' },
  { label: 'Linfield College', value: 'linfield' },
  { label: 'Lock Haven University of Pennsylvania', value: 'lhup' },
  { label: 'Loma Linda University', value: 'llu' },
  { label: 'Long Island University', value: 'liunet' },
  { label: 'Longwood College', value: 'lwc' },
  { label: 'Loras College', value: 'loras' },
  { label: 'Louisiana College', value: 'lacollege' },
  { label: 'Louisiana State University at Alexandria', value: 'lsua' },
  { label: 'Louisiana State University at Shreveport', value: 'louisiana state ' },
  { label: 'Louisiana State University', value: 'lsu' },
  { label: 'Louisiana Tech University', value: 'latech' },
  { label: 'Loyola College', value: 'loyola' },
  { label: 'Loyola Marymount University', value: 'lmu' },
  { label: 'Loyola University Chicago', value: 'luc' },
  { label: 'Luther College', value: 'luther' },
  { label: 'Luther Seminary', value: 'luthersem' },
  { label: 'Lycoming College', value: 'lycoming' },
  { label: 'Lynchburg College', value: 'lynchburg' },
  { label: 'Lyndon State College', value: 'lsc' },
  { label: 'Lyon College', value: 'lyon' },
  { label: 'Macalester College', value: 'macalstr' },
  { label: 'Maharishi University of Management', value: 'mum' },
  { label: 'Maine Maritime Academy', value: 'maine maritime a' },
  { label: 'Malone College', value: 'malone' },
  { label: 'Manhattan College', value: 'mancol' },
  { label: 'Mankato State University', value: 'mankato' },
  { label: 'Mansfield University of Pennsylvania', value: 'mnsfld' },
  { label: 'Marietta College', value: 'marietta' },
  { label: 'Marist College', value: 'marist' },
  { label: 'Marlboro College', value: 'marlboro' },
  { label: 'Marquette University', value: 'mu' },
  { label: 'Marshall University', value: 'marshall' },
  { label: 'Mary Baldwin College', value: 'mbc' },
  { label: 'Mary Washington College', value: 'mwc' },
  { label: 'Marymount College', value: 'marymt' },
  { label: 'Marymount University', value: 'marymount' },
  { label: 'Massachusetts Institute of Technology', value: 'mit' },
  { label: 'McMurry University', value: 'mcm' },
  { label: 'McNeese State University', value: 'mcneese' },
  { label: 'Medical College of Georgia', value: 'mcg' },
  { label: 'Medical College of Wisconsin', value: 'mcw' },
  { label: 'Mercer University', value: 'mercer' },
  { label: 'Mercyhurst College', value: 'mercy' },
  { label: 'Meredith College', value: 'meredith' },
  { label: 'Messiah College', value: 'messiah' },
  { label: 'Metropolitan State College of Denver', value: 'mscd' },
  { label: 'Metropolitan State University', value: 'metro' },
  { label: 'Miami Christian University', value: 'mcu' },
  { label: 'Miami University of Ohio', value: 'muohio' },
  { label: 'Michigan State University', value: 'msu' },
  { label: 'Michigan Technological University', value: 'mtu' },
  { label: 'Mid America Nazarene College', value: 'manc' },
  { label: 'Middle Georgia College', value: 'mgc' },
  { label: 'Middle Tennessee State University', value: 'mtsu' },
  { label: 'Middlebury College', value: 'middlebury' },
  { label: 'Midwestern State University', value: 'mwsu' },
  { label: 'Millersville University of Pennsylvania', value: 'millersv' },
  { label: 'Milligan College', value: 'milligan college' },
  { label: 'Millikin University', value: 'millikin' },
  { label: 'Millsaps College', value: 'millsaps' },
  { label: 'Milwaukee School of Engineering', value: 'msoe' },
  { label: 'Minneapolis College of Art and Design', value: 'mcad' },
  { label: 'Minot State University', value: 'misu' },
  { label: 'Mission College ', value: 'mission college' },
  { label: 'Mississippi College', value: 'mc' },
  { label: 'Mississippi State University', value: 'msstate' },
  { label: 'Mississippi University for Women', value: 'muw' },
  { label: 'Missouri Southern State College', value: 'mssc' },
  { label: 'Missouri Western State College', value: 'mwsc' },
  { label: 'Molloy College', value: 'molloy' },
  { label: 'Monmouth College', value: 'monm' },
  { label: 'Monmouth University', value: 'monmouth' },
  { label: 'Montana State University Billings', value: 'msubillings' },
  { label: 'Montana State University Bozeman', value: 'montana' },
  { label: 'Montana State University Northern', value: 'nmclites' },
  { label: 'Montana Tech', value: 'mtech' },
  { label: 'Montclair State University', value: 'montclair' },
  { label: 'Montreat College', value: 'montreat' },
  { label: 'Moorhead State University', value: 'moorhead' },
  { label: 'Moravian College', value: 'moravian' },
  { label: 'Morehouse College', value: 'morehouse colleg' },
  { label: 'Morgan State University', value: 'morgan' },
  { label: 'Mount Holyoke College', value: 'mtholyoke' },
  { label: 'Mount Saint Joseph College', value: 'msjnet' },
  { label: 'Mount Saint Mary College', value: 'msmc' },
  { label: 'Mount Senario College', value: 'mscfs' },
  { label: 'Mount Union College', value: 'muc' },
  { label: 'Murray State University', value: 'mursuky' },
  { label: 'Muskingum College', value: 'muskingum' },
  { label: 'National Defense University', value: 'ndu' },
  { label: 'National Technological University', value: 'ntu' },
  { label: 'National University', value: 'nu' },
  { label: 'National Louis University', value: 'nl' },
  { label: 'Naval Postgraduate School', value: 'nps' },
  { label: 'Nazareth College', value: 'naz' },
  { label: 'New College of California', value: 'newcollege' },
  { label: 'New England Institute of Technology', value: 'new england inst' },
  { label: 'New Hampshire College', value: 'nhc' },
  { label: 'New Jersey City University ', value: 'njcu' },
  { label: 'New Jersey Institute of Technology', value: 'njit' },
  { label: 'New Mexico Highlands University', value: 'nmhu' },
  { label: 'New Mexico Institute of Mining & Technology', value: 'nmt' },
  { label: 'New Mexico State University', value: 'nmsu' },
  { label: 'New York Institute of Technology', value: 'nyit' },
  { label: 'New York University', value: 'nyu' },
  { label: 'Newberry College', value: 'newberry' },
  { label: 'Niagara University', value: 'niagara' },
  { label: 'Nicholls State University', value: 'nich' },
  { label: 'Norfolk State University', value: 'nsu' },
  { label: 'North Adams State College', value: 'nasc' },
  { label: 'North Carolina A&T State University', value: 'ncat' },
  { label: 'North Carolina Central University', value: 'NCCU' },
  { label: 'North Carolina State University', value: 'ncsu' },
  { label: 'North Carolina Wesleyan College', value: 'ncwc' },
  { label: 'North Central Bible College', value: 'ncbc' },
  { label: 'North Dakota State University', value: 'ndsu' },
  { label: 'North Park College and Theological Seminary', value: 'npcts' },
  { label: 'Northeastern Illinois University', value: 'northeastern ill' },
  { label: 'Northeastern Louisiana University', value: 'nlu' },
  { label: 'Northeastern State University', value: 'nsuok' },
  { label: 'Northeastern University', value: 'northeastern' },
  { label: 'Northern Arizona University', value: 'nau' },
  { label: 'Northern Illinois University', value: 'niu' },
  { label: 'Northern Kentucky University', value: 'nku' },
  { label: 'Northern Michigan University', value: 'nmu' },
  { label: 'Northern State University', value: 'northern' },
  { label: 'Northland College', value: 'northland' },
  { label: 'Northwest Missouri State University', value: 'nwmissouri' },
  { label: 'Northwest Nazarene College', value: 'nnc' },
  { label: 'Northwestern College of Iowa', value: 'nwciowa' },
  { label: 'Northwestern State University', value: 'nsula' },
  { label: 'Northwestern University', value: 'nwu' },
  { label: 'Norwich University', value: 'norwich' },
  { label: 'Nova Southeastern University', value: 'nova' },
  { label: 'Oakland University', value: 'oakland' },
  { label: 'Oberlin College', value: 'oberlin' },
  { label: 'Occidental College', value: 'oxy' },
  { label: 'Ohio Dominican College', value: 'odc' },
  { label: 'Ohio Northern University', value: 'onu' },
  { label: 'Ohio State University, Columbus', value: 'ohio state' },
  { label: 'Ohio State University, Marion', value: 'marion' },
  { label: 'Ohio University, Athens', value: 'ohiou' },
  { label: 'Ohio Wesleyan University', value: 'owu' },
  { label: 'Oklahoma Baptist University', value: 'okbu' },
  { label: 'Oklahoma City University', value: 'okcu' },
  { label: 'Oklahoma State University', value: 'okstate' },
  { label: 'Old Dominion University', value: 'odu' },
  { label: 'Olivet Nazarene University', value: 'olivet' },
  { label: 'Oral Roberts University', value: 'oru' },
  { label: 'Orange Coast College ', value: 'orange coast college' },
  { label: 'Oregon Graduate Institute of Science and Technology', value: 'ogi' },
  { label: 'Oregon Health Sciences University', value: 'ohsu' },
  { label: 'Oregon Institute of Technology', value: 'oit' },
  { label: 'Oregon State University', value: 'orst' },
  { label: 'Otterbein College', value: 'otterbein' },
  { label: 'Our Lady of the Lake University', value: 'ollusa' },
  { label: 'Pace University', value: 'pace' },
  { label: 'Pacific Lutheran University', value: 'plu' },
  { label: 'Pacific Union College', value: 'puc' },
  { label: 'Pacific University', value: 'pacificu' },
  { label: 'Pacific Western University', value: 'pwu' },
  { label: 'Palm Beach Atlantic College', value: 'pbac' },
  { label: 'Peace College', value: 'Peace' },
  { label: 'Pembroke State University', value: 'pembroke' },
  { label: 'Pennsylvania College of Technology ', value: 'pct' },
  { label: 'Pennsylvania State System of Higher Education', value: 'sshechan' },
  { label: 'Pennsylvania State University Schuylkill Campus', value: 'sl' },
  { label: 'Pennsylvania State University', value: 'psu' },
  { label: 'Pensacola Christian College', value: 'pcci' },
  { label: 'Pepperdine University', value: 'pepperdine' },
  { label: 'Peru State College', value: 'peru' },
  { label: 'Philadelphia College of Textiles and Science', value: 'philacol' },
  { label: 'Phillips University', value: 'phillips' },
  { label: 'Pittsburg State University', value: 'pittstate' },
  { label: 'Pitzer College', value: 'pitzer' },
  { label: 'Platt College', value: 'platt' },
  { label: 'Plymouth State College', value: 'plymouth' },
  { label: 'Point Loma Nazarene College', value: 'point loma nazar' },
  { label: 'Polytechnic University of New York', value: 'poly' },
  { label: 'Polytechnic University of Puerto Rico', value: 'pupr' },
  { label: 'Pomona College', value: 'pomona' },
  { label: 'Portland State University', value: 'pdx' },
  { label: 'Prairie View A&M University', value: 'pvamu' },
  { label: 'Pratt Institute', value: 'pratt' },
  { label: 'Presbyterian College', value: 'presby' },
  { label: 'Prescott College', value: 'asu' },
  { label: 'Princeton University', value: 'princeton' },
  { label: 'Providence College', value: 'providence' },
  { label: 'Purdue University Calumet', value: 'purdue' },
  { label: 'Purdue University North Central', value: 'purduenc' },
  { label: 'Purdue University', value: 'purdue' },
  { label: 'Quincy University', value: 'quincy' },
  { label: 'Quinnipiac College', value: 'quinnipiac' },
  { label: 'Radford University', value: 'runet' },
  { label: 'Ramapo College', value: 'ramapo' },
  { label: 'Randolph Macon College', value: 'rmc' },
  { label: "Randolph Macon Woman's College", value: 'rmwc' },
  { label: 'Reed College', value: 'reed' },
  { label: 'Regent University', value: 'regent' },
  { label: 'Regis University', value: 'regis' },
  { label: 'Rensselaer Polytechnic Institute', value: 'rpi' },
  { label: 'Rhode Island College', value: 'ric' },
  { label: 'Rhodes College', value: 'rhodes' },
  { label: 'Rice University', value: 'rice' },
  { label: 'Richard Stockton College of New Jersey', value: 'stockton' },
  { label: 'Rider University', value: 'rider' },
  { label: 'Ripon College', value: 'Ripon' },
  { label: 'Rivier College', value: 'riv' },
  { label: 'Roanoke College', value: 'roanoke' },
  { label: 'Rochester Institute of Technology', value: 'rit' },
  { label: 'Rockford College', value: 'rockford' },
  { label: 'Rockhurst College', value: 'rockhurst' },
  { label: 'Rocky Mountain College', value: 'rocky' },
  { label: 'Roger Williams University', value: 'rwu' },
  { label: 'Rollins College', value: 'rollins' },
  { label: 'Rosary College', value: 'rosary' },
  { label: 'Rose Hulman Institute of Technology', value: 'rose hulman' },
  { label: 'Rowan College', value: 'rowan' },
  { label: 'Rutgers University, Camden', value: 'rutgers' },
  { label: 'Rutgers University, Newark', value: 'rutgers' },
  { label: 'Rutgers University', value: 'rutgers' },
  { label: 'Sacred Heart University', value: 'sacredheart' },
  { label: 'Saginaw Valley State University', value: 'svsu' },
  { label: 'Saint Anselm College', value: 'anselm' },
  { label: 'Saint Cloud State University', value: 'stcloud' },
  { label: "Saint Edward's University", value: 'stedwards' },
  { label: 'Saint Francis College', value: 'sfcpa' },
  { label: "Saint John's University", value: 'csbsju' },
  { label: "Saint Joseph's College", value: 'saintjoe' },
  { label: "Saint Joseph's University", value: 'sju' },
  { label: 'Saint Louis University', value: 'slu' },
  { label: 'Saint Mary College', value: 'smcks' },
  { label: "Saint Mary's College of California", value: 'stmarys ca' },
  { label: "Saint Mary's College", value: 'saintmarys' },
  { label: "Saint Mary's University of Minnesota", value: 'smumn' },
  { label: "Saint Michael's College", value: 'smcvt' },
  { label: 'Saint Olaf College', value: 'stolaf' },
  { label: 'Saint Vincent College', value: 'stvincent' },
  { label: 'Saint Xavier University', value: 'sxu' },
  { label: 'Salisbury State University', value: 'ssu' },
  { label: 'Salish Kootenai College', value: 'skc' },
  { label: 'Sam Houston State University', value: 'shsu' },
  { label: 'Samford University', value: 'samford' },
  { label: 'San Diego State University', value: 'sdsu' },
  { label: 'San Francisco State University', value: 'sfsu' },
  { label: 'San Jose State University', value: 'sjsu' },
  { label: 'Santa Clara University', value: 'scu' },
  { label: 'Sarah Lawrence College', value: 'slc' },
  { label: 'School of the Art Institute of Chicago', value: 'artic' },
  { label: 'Seattle Pacific University', value: 'spu' },
  { label: 'Seattle University', value: 'seattleu' },
  { label: 'Seton Hall University', value: 'shu' },
  { label: 'Sewanee, University of the South', value: 'sewanee' },
  { label: 'Shawnee State University', value: 'shawnee' },
  { label: 'Shenandoah University', value: 'su' },
  { label: 'Shippensburg University of Pennsylvania', value: 'ship' },
  { label: 'Shorter College', value: 'shorter' },
  { label: 'Simmons College', value: 'simmons' },
  { label: "Simon's Rock College", value: 'simons rock' },
  { label: 'Simpson College', value: 'simpson' },
  { label: 'Skidmore College', value: 'skidmore' },
  { label: 'Slippery Rock University of Pennsylvania', value: 'sru' },
  { label: 'Smith College', value: 'smith' },
  { label: 'Sonoma State University', value: 'sonoma' },
  { label: 'South Dakota School of Mines and Technology', value: 'sdsmt' },
  { label: 'South Dakota State University', value: 'sdstate' },
  { label: 'South Florida Institute of Technology ', value: 'south florida institute of technology' },
  { label: 'South Florida State College ', value: 'south florida state' },
  { label: 'South Georgia State College ', value: 'south georgia state' },
  { label: 'South Georgia Technical College ', value: 'south georgia technical' },
  { label: 'South Louisiana Community College ', value: 'south louisiana' },
  { label: 'South Piedmont Community College ', value: 'south piedmont' },
  { label: 'South Puget Sound Community College ', value: 'south puget sound' },
  { label: 'South Suburban College of Cook County ', value: 'south suburban of cook county' },
  { label: 'South Texas College ', value: 'south texas' },
  { label: 'South Texas College of Law', value: 'stcl' },
  { label: 'Southampton College', value: 'liunet' },
  { label: 'Southeast Applied Technology ', value: 'southeast applied technology' },
  { label: 'Southeast Community College Area ', value: 'southeast area' },
  { label: 'Southeast Missouri State University', value: 'semo' },
  { label: 'Southeastern College ', value: 'southeastern' },
  { label: 'Southeastern College at Wake Forest SBS ', value: 'southeastern at wake forest sbs' },
  { label: 'Southeastern Illinois College ', value: 'southeastern illinois' },
  { label: 'Southeastern Louisiana University', value: 'selu' },
  { label: 'Southeastern Oklahoma State University ', value: 'southeastern oklahoma state' },
  { label: 'Southeastern Technical College ', value: 'southeastern technical' },
  { label: 'Southern Arkansas University Main Campus ', value: 'southern arkansas main campus' },
  { label: 'Southern College of Technology', value: 'sct' },
  { label: 'Southern College', value: 'southern' },
  { label: 'Southern Community College ', value: 'southern' },
  { label: 'Southern Connecticut State University', value: 'scwww' },
  { label: 'Southern Illinois University Carbondale ', value: 'southern illinois carbondale' },
  { label: 'Southern Illinois University Carbondale', value: 'siu' },
  { label: 'Southern Illinois University Edwardsville', value: 'siue' },
  { label: 'Southern Illinois University', value: 'siu' },
  { label: 'Southern Maine Community College ', value: 'southern maine' },
  { label: 'Southern Methodist University', value: 'smu' },
  { label: 'Southern Nazarene University', value: 'snu' },
  { label: 'Southern Oregon State College', value: 'sosc' },
  { label: 'Southern Oregon University ', value: 'southern oregon' },
  { label: 'Southern Polytechnic State University ', value: 'southern polytechnic state' },
  { label: 'Southern State Community College ', value: 'southern state' },
  { label: 'Southern University at New Orleans ', value: 'suno' },
  { label: 'Southern University at Shreveport ', value: 'susla' },
  { label: 'Southern University', value: 'subr' },
  { label: 'Southern Utah University', value: 'suu' },
  {
    label: 'Southern West Virginia Community and Technical College ',
    value: 'southern west virginia community and technical',
  },
  { label: 'Southwest Baptist University', value: 'sbuniv' },
  { label: 'Southwest Missouri State University', value: 'smsu' },
  { label: 'Southwest State University', value: 'southwest' },
  { label: 'Southwest Tennessee Community College ', value: 'southwest tennessee' },
  { label: 'Southwest Texas State University', value: 'swt' },
  { label: 'Southwest University of Visual Arts ', value: 'southwest university of visual arts' },
  { label: 'Southwestern Adventist College', value: 'swac' },
  { label: 'Southwestern Adventist University ', value: 'southwestern adventist' },
  { label: 'Southwestern Oklahoma State University ', value: 'swosu' },
  { label: 'Southwestern University', value: 'southwestern' },
  { label: 'Sowela Technical Community College ', value: 'sowela technical' },
  { label: 'Spartanburg Methodist College ', value: 'spartanburg methodist' },
  { label: 'Spelman College', value: 'auc' },
  { label: 'Spokane Community College ', value: 'spokane' },
  { label: 'Spoon River College ', value: 'spoon river' },
  { label: 'Spring Arbor College', value: 'arbor' },
  { label: 'Spring Arbor University ', value: 'spring arbor' },
  { label: 'Spring Hill College', value: 'shc' },
  { label: 'Springfield College ', value: 'springfield' },
  { label: 'St Charles Community College ', value: 'st charles' },
  { label: 'ST JOHNS UNIVERSITY STATEN ISLAND CAMPUS ', value: 'st. johns' },
  { label: "St Vincent's College at Sacred Heart University ", value: 'st vincents at sacred heart' },
  { label: 'St. Ambrose University', value: 'sau' },
  { label: 'St. Andrews Presbyterian College', value: 'sapc' },
  { label: 'St. Bonaventure University', value: 'sbu' },
  { label: 'St. Cloud State University ', value: 'st. cloud state' },
  { label: 'St. Cloud Technical and Community College ', value: 'st. cloud' },
  { label: "St. Edward's University ", value: 'st. edwards' },
  { label: 'St. Francis College ', value: 'st. francis' },
  { label: "St. John's College Annapolis", value: 'sjca' },
  { label: "St. John's College Santa Fe", value: 'sjcsf' },
  { label: "St. John's University ", value: 'st. johns' },
  { label: 'St. Joseph College', value: 'sjc' },
  { label: "St. Joseph's College Long Island ", value: 'st. josephs long island' },
  { label: "St. Joseph's College", value: 'sjcme' },
  { label: 'St. Lawrence University', value: 'stlawu' },
  { label: 'St. Louis College of Pharmacy', value: 'stlcop' },
  { label: "St. Martin's College", value: 'stmartin' },
  { label: "St. Mary's College of Maryland ", value: 'st. marys of maryland' },
  { label: 'St. Olaf College ', value: 'st. olaf' },
  { label: 'St. Thomas Aquinas College ', value: 'st. thomas aquinas' },
  { label: 'St. Thomas University', value: 'stu' },
  { label: 'Stanford University', value: 'stanford' },
  { label: 'Stanly Community College ', value: 'stanly' },
  { label: 'Stark State College ', value: 'stark state' },
  { label: 'State College of Florida Sarasota Manatee ', value: 'state of florida sarasota manatee' },
  { label: 'State Technical College of Missouri ', value: 'state technical of missouri' },
  { label: 'State University of New York   University at Albany ', value: 'suny albany' },
  { label: 'State University of New York at Albany', value: 'albany' },
  { label: 'State University of New York at Binghamton', value: 'binghamton' },
  { label: 'State University of New York at Buffalo', value: 'buffalo' },
  { label: 'State University of New York at Farmingdale', value: 'farmingdale' },
  { label: 'State University of New York at Fredonia', value: 'fredonia' },
  { label: 'State University of New York at Oswego', value: 'oswego' },
  { label: 'State University of New York at Plattsburgh', value: 'plattsburgh' },
  { label: 'State University of New York at Stony Brook', value: 'sunysb' },
  { label: 'State University of New York College at Brockport', value: 'brockport' },
  { label: 'State University of New York College at Buffalo', value: 'snybuf' },
  { label: 'State University of New York College at Cortland', value: 'cortland' },
  { label: 'State University of New York College at Geneseo', value: 'geneseo' },
  { label: 'State University of New York College at New Paltz', value: 'newpaltz' },
  { label: 'State University of New York College at Oneonta', value: 'state university' },
  { label: 'State University of New York College at Potsdam', value: 'potsdam' },
  { label: 'State University of New York College of Agriculture and Technology at Cobleskill', value: 'cobleskill' },
  { label: 'State University of New York College of Environmental Science and Forestry', value: 'esf' },
  { label: 'State University of New York College of Technology at Alfred', value: 'alfredtech' },
  { label: 'State University of New York Institute of Technology at Utica/Rome', value: 'sunyit' },
  { label: 'State University of New York System', value: 'sunycentral' },
  { label: 'Stephen F Austin State University ', value: 'stephen f austin state' },
  { label: 'Stephen F. Austin State University', value: 'sfasu' },
  { label: 'Stephens College', value: 'stephens' },
  { label: 'Stetson University', value: 'stetson' },
  { label: 'Stevens Institute of Technology', value: 'stevens tech' },
  { label: 'Stockton University ', value: 'stockton' },
  { label: 'Stonehill College ', value: 'stonehill' },
  { label: 'Strayer College', value: 'strayer' },
  { label: 'Suffolk County Community College ', value: 'suffolk county' },
  { label: 'Suffolk University', value: 'suffolk' },
  { label: 'Sul Ross State University', value: 'sulross' },
  { label: 'Sullivan University ', value: 'sullivan' },
  { label: 'Summit College ', value: 'summit' },
  { label: 'Summit University of Louisiana', value: 'summitunivofla' },
  { label: 'SUN Area Career and Technology Center ', value: 'sun area career and technology center' },
  { label: 'SUNY Broome Community College ', value: 'suny broome' },
  { label: 'SUNY College at Brockport ', value: 'suny at brockport' },
  { label: 'SUNY College at New Paltz ', value: 'suny at new paltz' },
  { label: 'SUNY College at Old Westbury ', value: 'suny at old westbury' },
  { label: 'SUNY College at Oswego ', value: 'suny at oswego' },
  { label: 'SUNY College at Plattsburgh ', value: 'suny at plattsburgh' },
  { label: 'SUNY College of Technology at Canton ', value: 'suny canton' },
  { label: 'SUNY College of Technology at Delhi ', value: 'suny delhi' },
  { label: 'Surry Community College ', value: 'surry' },
  { label: 'Susquehanna University', value: 'susqu' },
  { label: 'Swarthmore College', value: 'swarthmore' },
  { label: 'Sweet Briar College', value: 'sbc' },
  { label: 'Syracuse University', value: 'syr' },
  { label: 'Tabor College', value: 'tabor' },
  { label: 'Tacoma Community College ', value: 'tacoma' },
  { label: 'Taft College ', value: 'taft' },
  { label: 'Talmudic College of Florida ', value: 'talmudic college of florida' },
  { label: 'Tarleton State University', value: 'tarleton' },
  { label: 'Taylor College ', value: 'taylor' },
  { label: 'Taylor University', value: 'tayloru' },
  { label: 'TCAT   Crossville ', value: 'tcat   crossville' },
  { label: 'Teachers College, Columbia University', value: 'tc' },
  { label: 'Technical College of the Lowcountry ', value: 'technical collegeof the lowcountry' },
  { label: 'Technology Center ', value: 'technology center' },
  { label: 'Teikyo Marycrest University', value: 'mcrest' },
  { label: 'Temple College ', value: 'temple' },
  { label: 'Temple University', value: 'temple' },
  { label: 'Tennessee College of Applied Technology   Covington ', value: 'tcat   covington' },
  { label: 'Tennessee College of Applied Technology   Crump ', value: 'tcat   crump' },
  { label: 'Tennessee College of Applied Technology   Dickson ', value: 'tcat   dickson' },
  { label: 'Tennessee College of Applied Technology   Elizabethton ', value: 'tcat   elizabethton' },
  { label: 'Tennessee College of Applied Technology   Hartsville ', value: 'tcat   hartsville' },
  { label: 'Tennessee College of Applied Technology   Jacksboro ', value: 'tcat   jacksboro' },
  { label: 'Tennessee College of Applied Technology   Jackson ', value: 'tcat   jackson' },
  { label: 'Tennessee College of Applied Technology   Knoxville ', value: 'tcat   knoxville' },
  { label: 'Tennessee College of Applied Technology   McMinnville ', value: 'tcat   mcminnville' },
  { label: 'Tennessee College of Applied Technology   Oneida/Huntsville ', value: 'tcat   oneida/huntsville' },
  { label: 'Tennessee College of Applied Technology   Pulaski ', value: 'tcat   pulaski' },
  { label: 'Tennessee College of Applied Technology   Whiteville ', value: 'tcat   whiteville' },
  { label: 'Tennessee State University', value: 'tnstate' },
  { label: 'Tennessee Technological University', value: 'tntech' },
  { label: 'Tennessee Wesleyan University ', value: 'tennessee wesleyan' },
  { label: 'Terra State Community College ', value: 'terra state' },
  { label: 'TESST College of Technology   Alexandria ', value: 'tesst college of technology   alexandria' },
  { label: 'Texarkana College ', value: 'texarkana' },
  { label: 'Texas A&M International University', value: 'tamiu' },
  { label: 'Texas A&M University College Station', value: 'tamu' },
  { label: 'Texas A&M University Corpus Christi', value: 'tamucc' },
  { label: 'Texas A&M University Kingsville', value: 'taiu' },
  { label: 'Texas Christian University', value: 'tcu' },
  { label: 'Texas College ', value: 'texas college' },
  { label: 'Texas Lutheran University ', value: 'texas lutheran' },
  { label: 'Texas Southern University', value: 'tsu' },
  { label: 'Texas Southmost College ', value: 'texas southmost' },
  { label: 'Texas State University ', value: 'texas state' },
  { label: 'Texas Tech University Health Sciences Center', value: 'ttuhsc' },
  { label: 'Texas Tech University', value: 'ttu' },
  { label: 'Texas Wesleyan University ', value: 'texas wesleyan' },
  { label: "Texas Woman's University ", value: 'texas womans' },
  { label: "Texas Woman's University", value: 'twu' },
  { label: 'Thaddeus Stevens College of Technology ', value: 'thaddeus stevens' },
  { label: 'The Catholic University of America', value: 'cua' },
  { label: 'The Chicago School of Professional Psychology', value: 'csopp' },
  { label: 'The Citadel', value: 'citadel' },
  { label: 'The College of New Jersey ', value: 'tcnj' },
  { label: 'The College of Saints John Fisher and Thomas More ', value: 'sjfc' },
  { label: 'The College of Wooster', value: 'wooster' },
  { label: 'The Community College of Baltimore County ', value: 'the community college of baltimore county' },
  { label: 'The Cooper Union for the Advancement of Science and Art', value: 'cooper' },
  { label: 'The Evergreen State College ', value: 'evergreen state' },
  { label: 'The Jewish Theological Seminary', value: 'jtsa' },
  { label: "The King's College ", value: 'kings college' },
  { label: 'The Rockefeller University', value: 'rockefeller' },
  { label: 'The Sage Colleges', value: 'sage' },
  { label: 'The Uniformed Services University of the Health Sciences', value: 'usuhs' },
  { label: 'The Union Institute', value: 'tui' },
  { label: 'The University of Alabama ', value: 'university of alabama' },
  { label: 'Thomas College', value: 'thomas' },
  { label: 'Thomas Edison State College', value: 'tesc' },
  { label: 'Thomas Jefferson University', value: 'tju' },
  { label: 'Thomas More College', value: 'thomasmore' },
  { label: 'Towson State University', value: 'towson' },
  { label: 'Transylvania University', value: 'transy' },
  { label: 'Trenton State College', value: 'trenton' },
  { label: 'Trinity College', value: 'trincoll' },
  { label: 'Trinity University', value: 'trinity' },
  { label: 'Troy State University', value: 'tsufl' },
  { label: 'Truman State University', value: 'truman' },
  { label: 'Tucson University', value: 'tucsonu' },
  { label: 'Tufts University', value: 'tufts' },
  { label: 'Tulane University', value: 'tulane' },
  { label: 'Tuskegee University', value: 'tusk' },
  { label: 'Union College', value: 'union' },
  { label: 'Union University', value: 'uu' },
  { label: 'United States Air Force Academy', value: 'usafa' },
  { label: 'United States International University', value: 'usiu' },
  { label: 'United States Merchant Marine Academy', value: 'usmma' },
  { label: 'United States Military Academy', value: 'usma' },
  { label: 'United States Naval Academy', value: 'nadn' },
  { label: 'University of Akron', value: 'uakron' },
  { label: 'University of Alabama at Birmingham', value: 'uab' },
  { label: 'University of Alabama at Huntsville', value: 'uah' },
  { label: 'University of Alabama at Tuscaloosa', value: 'ua' },
  { label: 'University of Alaska Anchorage', value: 'uaa' },
  { label: 'University of Alaska Fairbanks', value: 'alaska' },
  { label: 'University of Alaska Southeast', value: 'alaska' },
  { label: 'University of Alaska', value: 'alaska' },
  { label: 'University of Arizona', value: 'arizona' },
  { label: 'University of Arkansas   Fayetteville', value: 'uark' },
  { label: 'University of Arkansas   Little Rock', value: 'ualr' },
  { label: 'University of Arkansas   Monticello', value: 'uamont' },
  { label: 'University of Arkansas for Medical Sciences', value: 'uams' },
  { label: 'University of Baltimore', value: 'ubalt' },
  { label: 'University of Bridgeport', value: 'bridgeport' },
  { label: 'University of California, Berkeley', value: 'berkeley' },
  { label: 'University of California, Davis', value: 'ucdavis' },
  { label: 'University of California, Irvine', value: 'uci' },
  { label: 'University of California, Los Angeles', value: 'ucla' },
  { label: 'University of California, Riverside', value: 'ucr' },
  { label: 'University of California, San Diego', value: 'ucsd' },
  { label: 'University of California, San Francisco', value: 'ucsf' },
  { label: 'University of California, Santa Barbara', value: 'ucsb' },
  { label: 'University of California, Santa Cruz', value: 'ucsc' },
  { label: 'University of Central Arkansas', value: 'uca' },
  { label: 'University of Central Florida', value: 'ucf' },
  { label: 'University of Central Texas', value: 'university of ce' },
  { label: 'University of Charleston', value: 'uchaswv' },
  { label: 'University of Chicago', value: 'uchicago' },
  { label: 'University of Cincinnati', value: 'uc' },
  { label: 'University of Colorado at Boulder', value: 'colorado' },
  { label: 'University of Colorado at Colorado Springs', value: 'uccs' },
  { label: 'University of Colorado at Denver', value: 'cudenver' },
  { label: 'University of Colorado Health Sciences Center', value: 'hsc' },
  { label: 'University of Connecticut', value: 'uconn' },
  { label: 'University of Dallas', value: 'udallas' },
  { label: 'University of Dayton', value: 'udayton' },
  { label: 'University of Delaware', value: 'udel' },
  { label: 'University of Denver', value: 'du' },
  { label: 'University of Detroit Mercy', value: 'udmercy' },
  { label: 'University of Dubuque', value: 'dbq' },
  { label: 'University of Evansville', value: 'evansville' },
  { label: 'University of Florida', value: 'ufl' },
  { label: 'University of Georgia', value: 'uga' },
  { label: 'University of Great Falls', value: 'ugf' },
  { label: 'University of Guam', value: 'uog' },
  { label: 'University of Hartford', value: 'hartford' },
  { label: 'University of Hawaii at Hilo Physics and Astronomy', value: 'uhh' },
  { label: 'University of Hawaii at Manoa', value: 'hawaii' },
  { label: 'University of Houston', value: 'uh' },
  { label: 'University of Idaho', value: 'uidaho' },
  { label: 'University of Ilinois at Urbana Champaign', value: 'uiuc' },
  { label: 'University of Illinois at Chicago', value: 'uic' },
  { label: 'University of Illinois at Springfield', value: 'sangamon' },
  { label: 'University of Indianapolis', value: 'uindy' },
  { label: 'University of Iowa', value: 'uiowa' },
  { label: 'University of Kansas School of Medicine', value: 'kumc' },
  { label: 'University of Kansas', value: 'ukans' },
  { label: 'University of Kentucky', value: 'uky' },
  { label: 'University of La Verne', value: 'ulaverne' },
  { label: 'University of Louisville', value: 'louisville' },
  { label: 'University of Maine at Farmington', value: 'umf' },
  { label: 'University of Maine at Fort Kent', value: 'umfk' },
  { label: 'University of Maine at Machias', value: 'umm' },
  { label: 'University of Maine at Presque Island', value: 'umpi' },
  { label: 'University of Maine System', value: 'maine' },
  { label: 'University of Maine', value: 'ume' },
  { label: 'University of Maryland   University College', value: 'umuc' },
  { label: 'University of Maryland at Baltimore County', value: 'umbc' },
  { label: 'University of Maryland at Baltimore', value: 'ab' },
  { label: 'University of Maryland at College Park', value: 'umcp' },
  { label: 'University of Massachusetts at Amherst', value: 'umass' },
  { label: 'University of Massachusetts at Dartmouth', value: 'umassd' },
  { label: 'University of Massachusetts at Lowell', value: 'uml' },
  { label: 'University of Massachusetts System', value: 'umassp' },
  { label: 'University of Memphis', value: 'memphis' },
  { label: 'University of Miami', value: 'miami' },
  { label: 'University of Michigan Ann Arbor', value: 'umich' },
  { label: 'University of Michigan Dearborn', value: 'umd' },
  { label: 'University of Minnesota Crookston', value: 'crk' },
  { label: 'University of Minnesota Duluth', value: 'd' },
  { label: 'University of Minnesota Morris', value: 'mrs' },
  { label: 'University of Minnesota Twin Cities', value: 'umn' },
  { label: 'University of Minnesota', value: 'umn' },
  { label: 'University of Mississippi Medical Center', value: 'umsmed' },
  { label: 'University of Mississippi', value: 'olemiss' },
  { label: 'University of Missouri System', value: 'missouri' },
  { label: 'University of Missouri Columbia', value: 'missouri' },
  { label: 'University of Missouri Kansas City', value: 'umkc' },
  { label: 'University of Missouri Rolla', value: 'umr' },
  { label: 'University of Missouri Saint Louis', value: 'umsl' },
  { label: 'University of Montana', value: 'umt' },
  { label: 'University of Nebraska, Kearney', value: 'unk' },
  { label: 'University of Nebraska, Lincoln', value: 'unl' },
  { label: 'University of Nebraska, Omaha', value: 'unomaha' },
  { label: 'University of Nevada, Las Vegas', value: 'unlv' },
  { label: 'University of Nevada, Reno', value: 'unr' },
  { label: 'University of New England', value: 'une' },
  { label: 'University of New Hampshire, Durham', value: 'unh' },
  { label: 'University of New Haven', value: 'newhaven' },
  { label: 'University of New Mexico', value: 'unm' },
  { label: 'University of New Orleans', value: 'uno' },
  { label: 'University of North Carolina at Asheville', value: 'unca' },
  { label: 'University of North Carolina at Chapel Hill', value: 'unc' },
  { label: 'University of North Carolina at Charlotte', value: 'uncc' },
  { label: 'University of North Carolina at Greensboro', value: 'uncg' },
  { label: 'University of North Carolina at Wilmington', value: 'uncwil' },
  { label: 'University of North Carolina System', value: 'unc' },
  { label: 'University of North Dakota', value: 'und' },
  { label: 'University of North Florida', value: 'unf' },
  { label: 'University of North Texas Health Science Center', value: 'hsc' },
  { label: 'University of North Texas', value: 'unt' },
  { label: 'University of Northern Colorado', value: 'univnorthco' },
  { label: 'University of Northern Iowa', value: 'uni' },
  { label: 'University of Notre Dame', value: 'nd' },
  { label: 'University of Oklahoma', value: 'uoknor' },
  { label: 'University of Oregon', value: 'uoregon' },
  { label: 'University of Pennsylvania', value: 'upenn' },
  { label: 'University of Phoenix', value: 'uophx' },
  { label: 'University of Pittsburgh at Johnstown', value: 'pitt' },
  { label: 'University of Pittsburgh', value: 'pitt' },
  { label: 'University of Portland', value: 'uofport' },
  { label: 'University of Puerto Rico', value: 'upr' },
  { label: 'University of Puget Sound', value: 'ups' },
  { label: 'University of Redlands', value: 'uor' },
  { label: 'University of Rhode Island', value: 'uri' },
  { label: 'University of Richmond', value: 'urich' },
  { label: 'University of Rochester', value: 'rochester' },
  { label: 'University of Saint Thomas', value: 'stthom' },
  { label: 'University of San Diego', value: 'acusd' },
  { label: 'University of San Francisco', value: 'usfca' },
  { label: 'University of Sarasota', value: 'sarasota' },
  { label: 'University of Science & Arts of Oklahoma', value: 'usao' },
  { label: 'University of Scranton', value: 'uofs' },
  { label: 'University of Sioux Falls', value: 'thecoo' },
  { label: 'University of South Alabama', value: 'usouthal' },
  { label: 'University of South Carolina   Aiken', value: 'sc' },
  { label: 'University of South Carolina', value: 'scarolina' },
  { label: 'University of South Dakota', value: 'usd' },
  { label: 'University of South Florida', value: 'usf' },
  { label: 'University of Southern California', value: 'usc' },
  { label: 'University of Southern Colorado', value: 'uscolo' },
  { label: 'University of Southern Indiana', value: 'usi' },
  { label: 'University of Southern Maine', value: 'usm' },
  { label: 'University of Southern Mississippi', value: 'usm' },
  { label: 'University of Southwestern Louisiana', value: 'usl' },
  { label: 'University of Tampa', value: 'utampa' },
  { label: 'University of Tennessee, Knoxville', value: 'utk' },
  { label: 'University of Tennessee, Martin', value: 'utm' },
  { label: 'University of Texas at Arlington', value: 'uta' },
  { label: 'University of Texas at Austin', value: 'utexas' },
  { label: 'University of Texas at Brownsville', value: 'utb' },
  { label: 'University of Texas at Dallas', value: 'utdallas' },
  { label: 'University of Texas at El Paso', value: 'utep' },
  { label: 'University of Texas at San Antonio', value: 'utsa' },
  { label: 'University of Texas at Tyler', value: 'university of te' },
  { label: 'University of Texas Health Center at Tyler', value: 'uthct' },
  { label: 'University of Texas Health Science Center at Houston', value: 'uth' },
  { label: 'University of Texas Health Science Center at San Antonio', value: 'uthscsa' },
  { label: 'University of Texas M.D. Anderson Cancer Center', value: 'utmdacc' },
  { label: 'University of Texas Medical Branch', value: 'utmb' },
  { label: 'University of Texas Southwestern Medical Center at Dallas', value: 'swmed' },
  { label: 'University of Texas System', value: 'utsystem' },
  { label: 'University of Texas Pan American', value: 'panam' },
  { label: 'University of the District of Columbia', value: 'udc' },
  { label: 'University of the Ozarks', value: 'ozarks' },
  { label: 'University of the Pacific', value: 'uop' },
  { label: 'University of the Virgin Islands', value: 'uvi' },
  { label: 'University of Toledo', value: 'utoledo' },
  { label: 'University of Tulsa', value: 'utulsa' },
  { label: 'University of Utah', value: 'utah' },
  { label: 'University of Vermont', value: 'uvm' },
  { label: 'University of Virginia, Charlottesville', value: 'virginia' },
  { label: 'University of Washington', value: 'washington' },
  { label: 'University of West Alabama', value: 'westal' },
  { label: 'University of West Florida', value: 'uwf' },
  { label: 'University of Wisconsin System', value: 'uwsa' },
  { label: 'University of Wisconsin Eau Claire', value: 'uwec' },
  { label: 'University of Wisconsin Green Bay', value: 'uwgb' },
  { label: 'University of Wisconsin LaCrosse', value: 'uwlax' },
  { label: 'University of Wisconsin Madison', value: 'wisc' },
  { label: 'University of Wisconsin Milwaukee', value: 'uwm' },
  { label: 'University of Wisconsin Oshkosh', value: 'uwosh' },
  { label: 'University of Wisconsin Parkside', value: 'uwp' },
  { label: 'University of Wisconsin Platteville', value: 'uwplatt' },
  { label: 'University of Wisconsin River Falls', value: 'uwrf' },
  { label: 'University of Wisconsin Stevens Point', value: 'uwsp' },
  { label: 'University of Wisconsin Stout', value: 'uwstout' },
  { label: 'University of Wisconsin Superior', value: 'uwsuper' },
  { label: 'University of Wisconsin Whitewater', value: 'uww' },
  { label: 'University of Wyoming', value: 'uwyo' },
  { label: 'Upper Iowa University', value: 'uiu' },
  { label: 'Ursinus College', value: 'ursinus' },
  { label: 'Ursuline College', value: 'ursuline college' },
  { label: 'Utah State University', value: 'usu' },
  { label: 'Utah Valley State College', value: 'uvsc' },
  { label: 'Valdosta State University', value: 'valdosta' },
  { label: 'Valley City State University', value: 'vcsu' },
  { label: 'Valparaiso University', value: 'valpo' },
  { label: 'Vanderbilt University', value: 'vanderbilt' },
  { label: 'Vassar College', value: 'vassar' },
  { label: 'Vermont Technical College', value: 'vtc' },
  { label: 'Villa Julie College', value: 'vjc' },
  { label: 'Villanova University', value: 'vill' },
  { label: 'Virginia Commonwealth University', value: 'vcu' },
  { label: 'Virginia Intermont College', value: 'vic' },
  { label: 'Virginia Military Institute', value: 'vmi' },
  { label: 'Virginia Polytechnic Institute and State University', value: 'vt' },
  { label: 'Virginia State University', value: 'vsu' },
  { label: 'Virginia Wesleyan College', value: 'vwc' },
  { label: 'Wabash College', value: 'wabash' },
  { label: 'Wake Forest University', value: 'wfu' },
  { label: 'Walden University', value: 'waldenu' },
  { label: 'Walla Walla College', value: 'wwc' },
  { label: 'Warren Wilson College', value: 'warren wilson' },
  { label: 'Wartburg College', value: 'wartburg' },
  { label: 'Washburn University', value: 'wuacc' },
  { label: 'Washington & Lee University', value: 'wlu' },
  { label: 'Washington Bible College/Capital Bible Seminary', value: 'bible' },
  { label: 'Washington College', value: 'washcoll' },
  { label: 'Washington State University at Tri Cities', value: 'wsu' },
  { label: 'Washington State University at Vancouver', value: 'vancouver' },
  { label: 'Washington State University', value: 'wsu' },
  { label: 'Washington University, Saint Louis', value: 'wustl' },
  { label: 'Wayne State University', value: 'wayne' },
  { label: 'Waynesburg College', value: 'waynesburg' },
  { label: 'Weber State University', value: 'weber' },
  { label: 'Webster University', value: 'websteruniv' },
  { label: 'Wellesley College', value: 'wellesley' },
  { label: 'Wells College', value: 'wells' },
  { label: 'Wentworth Institute of Technology', value: 'wit' },
  { label: 'Wesley College', value: 'wesley' },
  { label: 'Wesleyan University', value: 'wesleyan' },
  { label: 'West Chester University of Pennsylvania', value: 'wcupa' },
  { label: 'West Coast University', value: 'wcula' },
  { label: 'West Georgia College', value: 'westga' },
  { label: 'West Liberty State College', value: 'wlsc' },
  { label: 'West Texas A&M University', value: 'wtamu' },
  { label: 'West Virginia University at Parkersburg', value: 'wvup' },
  { label: 'West Virginia University', value: 'wvu' },
  { label: 'Western Carolina University', value: 'wcu' },
  { label: 'Western Connecticut State University', value: 'wcsu' },
  { label: 'Western Illinois University', value: 'wiu' },
  { label: 'Western Kentucky University', value: 'wku' },
  { label: 'Western Maryland College', value: 'wmc' },
  { label: 'Western Michigan University', value: 'wmich' },
  { label: 'Western Montana College', value: 'wmc' },
  { label: 'Western New England College', value: 'wnec' },
  { label: 'Western New Mexico University', value: 'wnmu' },
  { label: 'Western State College', value: 'wsc' },
  { label: 'Western Washington University', value: 'wwu' },
  { label: 'Westfield State College', value: 'wsc' },
  { label: 'Westminster College of Salt Lake City', value: 'wcslc' },
  { label: 'Westminster College', value: 'westminster' },
  { label: 'Westminster Theological Seminary', value: 'wts' },
  { label: 'Westmont College', value: 'westmont' },
  { label: 'Wheaton College, Norton MA', value: 'wheatonma' },
  { label: 'Wheaton College', value: 'wheaton' },
  { label: 'Wheeling Jesuit College', value: 'wjc' },
  { label: 'Whitman College', value: 'whitman' },
  { label: 'Whittier College', value: 'whittier' },
  { label: 'Whitworth College', value: 'whitworth' },
  { label: 'Wichita State University', value: 'twsu' },
  { label: 'Widener University', value: 'widener' },
  { label: 'Wilberforce University', value: 'wilberforce' },
  { label: 'Wilkes University', value: 'wilkes' },
  { label: 'Willamette University', value: 'willamette' },
  { label: 'William Howard Taft University', value: 'taftu' },
  { label: 'William Jewell College', value: 'jewell' },
  { label: 'William Mitchell College of Law', value: 'wmitchell' },
  { label: 'William Paterson College', value: 'wilpaterson' },
  { label: 'William Penn College', value: 'wmpenn' },
  { label: 'William Woods University', value: 'wmwoods' },
  { label: 'Williams College', value: 'williams' },
  { label: 'Wilmington College', value: 'wilmington' },
  { label: 'Winona State University', value: 'winona' },
  { label: 'Winthrop University', value: 'winthrop' },
  { label: 'Wittenberg University', value: 'wittenberg' },
  { label: 'Wofford College', value: 'wofford' },
  { label: 'Woodbury University', value: 'woodburyu' },
  { label: 'Worcester Polytechnic Institute', value: 'wpi' },
  { label: 'Wright State University', value: 'wright' },
  { label: 'Xavier University of Louisiana', value: 'xula' },
  { label: 'Yale University', value: 'yale' },
  { label: 'Yeshiva University', value: 'yu' },
  { label: 'York College of Pennsylvania', value: 'ycp' },
  { label: 'Youngstown State University', value: 'ysu' },
];

export default universities;
